<template>
  <div
    class="w-full aspect-[1/2] sm:aspect-2/1 relative max-h-[85vh] lg:max-h-[70vh] md:min-h-[600px]"
  >
    <nuxt-img
      v-if="block.BackgroundImageMobile && block.BackgroundImageMobile.Url && isMobile"
      class="w-full object-cover absolute top-0 left-0 h-full"
      :src="block.BackgroundImageMobile.Url"
      preset="standard"
    />
    <nuxt-img
      v-else-if="block.BackgroundImage && block.BackgroundImage.Url"
      class="w-full object-cover absolute top-0 left-0 h-full"
      :src="block.BackgroundImage.Url"
      preset="hero"
      sizes="sm:100vw md:1350px"
    />
    <div
      class="layout-container flex items-end h-full relative aspect-[1/2] sm:aspect-2/1 max-h-[85vh] lg:max-h-[70vh] md:min-h-[600px] w-full"
    >
      <div class="pb-72 sm:pb-80 md:pb-[144px] md:max-w-1/2" :style="`color: ${block.ThemeColor}`">
        <div
          v-if="block.Tagline"
          class="type-tag-xs mb-16 text-white"
          :style="`color: ${block.ThemeColor}`"
        >
          {{ block.Tagline }}
        </div>
        <WysiwygWrapper
          v-if="block.MainBody"
          :html="block.MainBody"
          style-config="wysiwyg"
          :class=" {
            'has-theme-color': props.block.ThemeColor
          }"
        />
        <div
          v-if="block.PrimaryButton || block.SecondaryButton"
          class="flex mt-24 gap-16 flex-wrap sm:flex-wrap"
        >
          <nuxt-link
            v-if="block.PrimaryButton && block.PrimaryButton.LinkUrl"
            :to="block.PrimaryButton.LinkUrl"
            class="btn btn-primary hover:brightness-90"
            :style="themePrimaryButton"
          >
            {{ block.PrimaryButton.Text }}
          </nuxt-link>
          <nuxt-link
            v-if="block.SecondaryButton && block.SecondaryButton.LinkUrl"
            :to="block.SecondaryButton.LinkUrl"
            class="btn btn-basic"
            :style="themeBasicButton"
          >
            {{ block.SecondaryButton.Text }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type HeroBlockFragment } from '#gql';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';

const props = defineProps<{
  block: HeroBlockFragment,
  isMobile: boolean,
  isEditMode: boolean,
  lessPadding?: boolean
}>();

const { themePrimaryButton, themeBasicButton } = useThemeColors(props.block.ThemeColor);
</script>
